import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
const debug = process.env.NODE_ENV !== "production";

const state = {
    lang: "en-US",
    petLoading: false,
    isUserLoggedIn: false,
    userInfo: {},
    inviteInfo: {},
    userInviteCode: "",
};

const mutations = {
    updateLang(state, value) {
        state.lang = value;
    },
    updatePetLoading(state, value) {
        state.petLoading = value;
    },
    updateIsUserLoggedIn(state, value) {
        state.isUserLoggedIn = value;
    },
    updateUserInfo(state, value) {
        state.userInfo = value;
    },
    updateInviteInfo(state, value) {
        state.inviteInfo = value;
    },
    updateUserInviteCode(state, value) {
        state.userInviteCode = value;
    },
};
const actions = {};

export default createStore({
    state,
    mutations,
    actions,
    strict: debug,
    plugins: [createPersistedState()],
});
