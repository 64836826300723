const Home = () => import("@/views/Home");
const HowToPlay = () => import("@/views/HowToPlay");
const NFT = () => import("@/views/NFT");
const TaskPoints = () => import("@/views/TaskPoints/index2");
const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
            status: "1",
        },
    },
    {
        path: "/how-to-play",
        name: "how-to-play",
        component: HowToPlay,
        meta: {
            status: "1",
        },
    },
    {
        path: "/nft",
        name: "nft",
        component: NFT,
        meta: {
            status: "1",
        },
    },
    {
        path: "/task-points",
        name: "task-points",
        component: TaskPoints,
    },
];
export default routes;
