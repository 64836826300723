export default {
    public: {
        copy_success: "Copied successfully",
        download_success: "Saved successfully",
        button: {
            text: "Confirm",
            jump: "Skip",
            text_ok: "Confirm",
            text_ok1: "OK",
            cancel: "Cancel",
        },
        networkError: {
            text1: "Error, please try again!",
            text2: "Login expired or error occurred, please login again!",
            text3: "The requested resource does not exist!",
            mail: "Please open it in the Supe App!",
            errorProcess: "You are not in the App environment, please open the Supe App to visit!",
            errorMethodsNotDefine: "The method does not exist!",
        },
        refresh: {
            text1: "Drag down to referesh...",
            text2: "Release to referesh...",
            text3: "Loading...",
            text4: "Loading successfully~",
            text5: "noMore~",
        },
    },
    header2: {
        title_task_points: "Wish Points",
    },
    login: {
        errorMessage: {
            10619: "Verification code error",
            1700006: "Email format error",
        },
    },
    task: {
        text1: "Follow Twitter",
        text2: "Join Discord",
        text3: "Join Telegram",
        text4: "Refer Friends",
        text5_0: "GO",
        text5_1: "Done",
    },
};
