export default {
    public: {
        copy_success: "复制成功",
        download_success: "保存成功",
        button: {
            text: "确定",
            jump: "跳过",
            text_ok: "确认",
            text_ok1: "好的",
            cancel: "取消",
        },
        networkError: {
            text1: "错误，请重试！",
            text2: "登录过期或出现错误，请重新登录！",
            text3: "请求的资源不存在！",
            mail: "请在Supe App内打开!",
            errorProcess: "您不在App环境内，请打开Supe App访问！",
            errorMethodsNotDefine: "方法不存在!",
        },
        refresh: {
            text1: "向下拖动刷新...",
            text2: "松开刷新...",
            text3: "加载中...",
            text4: "加载成功~",
            text5: "没有更多了~",
        },
    },
    header2: {
        title_task_points: "Wish Points",
    },
    login: {
        errorMessage: {
            10619: "Verification code error",
            1700006: "Email format error",
        },
    },
    task: {
        text1: "Follow Twitter",
        text2: "Join Discord",
        text3: "Join Telegram",
        text4: "Refer Friends",
        text5_0: "GO",
        text5_1: "Done",
    },
};
