import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import store from "@/store";
import useSetInviter from "@/hooks/useSetInviter.js";
import { myVanToast } from "@/plugins/vant.js";
import api from "@/service/api.js";

const router = createRouter({
    linkActiveClass: "active",
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const isUserLoggedIn = store.state.isUserLoggedIn;
    if (isUserLoggedIn) {
        next();
    } else {
        if (to.meta.status) {
            next();
        } else {
            next("/home");
        }
    }
    try {
        const code = to.query.invite_code;
        if (code) {
            // 设置邀请人
            store.commit("updateUserInviteCode", code);
            if (isUserLoggedIn && code) {
                const storeInviteInfo = store.state.inviteInfo;
                useSetInviter(store, myVanToast, api, storeInviteInfo);
            }
        } else {
            store.commit("updateUserInviteCode", "");
        }
    } catch (err) {
        store.commit("updateUserInviteCode", "");
    }
});

export default router;
