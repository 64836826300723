//统一管理api
import { base_pets_cdwar } from "./base"; // 导入接口域名列表
import { post, _post } from "./tool"; // 导入http中创建的axios实例
//登录
export const setUserLogin = (params) => post(base_pets_cdwar + "/user/login", params);
// 发送验证码
export const setEmailVerifyCode = (params) => post(base_pets_cdwar + "/user/send/email_verify_code", params);
//腾讯云签名
export const getStorageCred = (params) => _post(base_pets_cdwar + "/storage/cred", params);
//设置用户信息
export const setUserInfo = (params) => _post(base_pets_cdwar + "/user/set_user_info", params);
// 获取积分信息
export const getPointInfo = (params) => _post(base_pets_cdwar + "/point/info", params);
// 领取积分
export const getPointClaim = (params) => _post(base_pets_cdwar + "/point/claim", params);
// 领取积分
export const setInvite = (params) => _post(base_pets_cdwar + "/invite/set", params);
export default {
    setUserLogin,
    setEmailVerifyCode,
    getStorageCred,
    setUserInfo,
    getPointInfo,
    getPointClaim,
    setInvite,
};
