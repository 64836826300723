export default function ($store, $Toast, $api, invite_info) {
    const code = $store.state.userInviteCode;
    if (code && invite_info && code != invite_info.invite_code && !invite_info.parent_invite_code) {
        $api.setInvite({
            invite_code: code,
        })
            .then((res) => {
                if (!res.error_code) {
                    const common = res.common;
                    $store.commit("updateUserInfo", common.user_info);
                    $store.commit("updateInviteInfo", common.invite_info);
                    setTimeout(() => {
                        $Toast({
                            message: "Inviter set up successfully",
                            duration: 2000,
                        });
                    }, 2000);
                } else {
                    $store.commit("updateUserInviteCode", "");
                    if (res.error_code == 1700001) {
                        setTimeout(() => {
                            $Toast({
                                message: "The invitation code does not exist",
                                duration: 2000,
                            });
                        }, 2000);
                    } else {
                        setTimeout(() => {
                            $Toast({ message: res.message, duration: 2000 });
                        }, 2000);
                    }
                }
            })
            .catch(() => {});
    }
}
